// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("ITCAvantGardeStd-Bold.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("ITCAvantGardeStd-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("ITCAvantGardeStd-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("ITCAvantGardeStd-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'ITC Avant Garde';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    src: local('ITC Avant Garde Gothic Std Bold'), local('ITCAvantGardeStd-Bold'),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
    font-weight: bold;
    font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/ITC-Avant-Garde.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,4CAAqC;IACrC;;;;8DAImD;IACnD,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'ITC Avant Garde';\n    src: url('ITCAvantGardeStd-Bold.eot');\n    src: local('ITC Avant Garde Gothic Std Bold'), local('ITCAvantGardeStd-Bold'),\n    url('ITCAvantGardeStd-Bold.eot?#iefix') format('embedded-opentype'),\n    url('ITCAvantGardeStd-Bold.woff2') format('woff2'),\n    url('ITCAvantGardeStd-Bold.woff') format('woff'),\n    url('ITCAvantGardeStd-Bold.ttf') format('truetype');\n    font-weight: bold;\n    font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
