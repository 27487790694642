import React, { useState, useRef } from 'react';
import css from "./Header.module.css";
import { Link, scroller } from "react-scroll";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate, useLocation } from "react-router-dom";


const Header = () => {

   /* STATES */
   const [selectLng, setSelectLng] = useState("CO");

   /* I18N */
   const { t, i18n } = useTranslation(['header']);

   /* HOOKS */
   const navigate = useNavigate();
   const location = useLocation();


   return (
      <header className={css.container}>
         <div className={`${css['header-language']} max-width-global`}>

         </div>

         <div className={`${css['header-content']} max-width-global`}>
            <h1 className={css['header-logo']} onClick={() => navigate('/', { replace: true })} >
               Convers<span>IA</span>
            </h1>

            { location.pathname === '/' && (
               <nav className={css['header-menu']}>
                  <ul>
                     <li>
                        <Link to="herramienta-scroll-menu" spy={true} smooth={true} offset={-70}>
                           { t('optionMenu1') }
                        </Link>
                     </li>
                     <li>
                        <Link to="precio-scroll-menu" spy={true} smooth={true} offset={-150}>
                           { t('optionMenu2') }
                        </Link>
                     </li>
                     <li>
                        <Link to="contacto-scroll-menu" spy={true} smooth={true} offset={-100}>
                           { t('optionMenu3') }
                        </Link>
                     </li>
                  </ul>
               </nav>
            )}

            <ReactFlagsSelect
               selected={selectLng}
               alignOptionsToRight
               className={css['control-select-lng']}
               countries={['CO', 'US']}
               customLabels={{ 'CO': 'ES', 'US': 'EN' }}
               onSelect={code => {
                  code === 'CO' ? i18n.changeLanguage('es') : i18n.changeLanguage('en');
                  setSelectLng(code);
               }}
            />

            <button
               type="button"
               className={`${css['btn-schedule']} button-blue-global`}
               onClick={() => {
                  scroller.scrollTo("contacto-scroll-menu", {
                     spy: true,
                     smooth: true,
                     offset: -100
                  });
               }}
            >
               { t('textButton') }
            </button>
         </div>
      </header>
   );
};

export default Header;
