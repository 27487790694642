import React from 'react';
import css from "./Loading.module.css";

const Loading = () => {
   return (
      <div className={css['loader-container']}>
         <div className={css['custom-loader']} />
         <p>
            Cargando recursos...
            <br/><br/>
            Loading resources...
         </p>
      </div>
   );
};

export default Loading;
