// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-player {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 15px;
}

.container-player button {
    margin-left: 8%;
}

.audio-player {
    width: 55%;
}

.audio-player audio {
    width: 100%;
}

.audio-player h3 {
    padding: 10px 0 5px 5px;
    font-size: 18px;
    text-align: center;
}



@media screen and (max-width: 768px) {
    .container-player {
        justify-content: center;
        margin-left: 0;
    }

}

@media screen and (max-width: 576px) {
    .container-player button {
        margin-left: 5%;
    }
    
    .audio-player {
        width: 65%;
    }

    .audio-player h3 {
        font-size: 16px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/BodyLanding/DemoPlayer/IndividualAudio/IndividualAudio.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,kBAAkB;AACtB;;;;AAIA;IACI;QACI,uBAAuB;QACvB,cAAc;IAClB;;AAEJ;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".container-player {\n    display: flex;\n    align-items: center;\n    margin-bottom: 20px;\n    margin-left: 15px;\n}\n\n.container-player button {\n    margin-left: 8%;\n}\n\n.audio-player {\n    width: 55%;\n}\n\n.audio-player audio {\n    width: 100%;\n}\n\n.audio-player h3 {\n    padding: 10px 0 5px 5px;\n    font-size: 18px;\n    text-align: center;\n}\n\n\n\n@media screen and (max-width: 768px) {\n    .container-player {\n        justify-content: center;\n        margin-left: 0;\n    }\n\n}\n\n@media screen and (max-width: 576px) {\n    .container-player button {\n        margin-left: 5%;\n    }\n    \n    .audio-player {\n        width: 65%;\n    }\n\n    .audio-player h3 {\n        font-size: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
