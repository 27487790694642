// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactIntenalPage_container__5Bli9 {
    padding-top: 80px;
    margin-bottom: 50px;
}

.ContactIntenalPage_container__5Bli9 h1 {
    font-size: 46px;
    text-align: center;
    margin-bottom: 80px;
}


@media screen and (max-width: 1536px) {
    .ContactIntenalPage_container__5Bli9 h1 {
        font-size: 40px;
    }
}

@media screen and (max-width: 1200px) {
    .ContactIntenalPage_container__5Bli9 h1 {
        font-size: 36px;
    }
}

@media screen and (max-width: 992px) {
    .ContactIntenalPage_container__5Bli9 {
        padding-top: 50px;
    }

    .ContactIntenalPage_container__5Bli9 h1 {
        font-size: 34px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 576px) {
    .ContactIntenalPage_container__5Bli9 h1 {
        font-size: 30px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ContactIntenalPage/ContactIntenalPage.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB;;;AAGA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,eAAe;QACf,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".container {\n    padding-top: 80px;\n    margin-bottom: 50px;\n}\n\n.container h1 {\n    font-size: 46px;\n    text-align: center;\n    margin-bottom: 80px;\n}\n\n\n@media screen and (max-width: 1536px) {\n    .container h1 {\n        font-size: 40px;\n    }\n}\n\n@media screen and (max-width: 1200px) {\n    .container h1 {\n        font-size: 36px;\n    }\n}\n\n@media screen and (max-width: 992px) {\n    .container {\n        padding-top: 50px;\n    }\n\n    .container h1 {\n        font-size: 34px;\n        margin-bottom: 50px;\n    }\n}\n\n@media screen and (max-width: 576px) {\n    .container h1 {\n        font-size: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactIntenalPage_container__5Bli9`
};
export default ___CSS_LOADER_EXPORT___;
