import React from 'react';
import Seccion1 from "./Seccion1";
import TecnologiasPartners from "./TecnologiasPartners";
import Precio from "./Precio/Precio";
import Contacto from "./Contacto";
import DemoPlayer from "./DemoPlayer";

const BodyLanding = () => {
   return (
      <>
         <Seccion1 />
         <TecnologiasPartners />
         <DemoPlayer />
         <Precio />
         <Contacto />
      </>
   );
};

export default BodyLanding;
