import React from 'react';
import css from "./Precio.module.css";
import {Link} from "react-scroll";
import { useTranslation } from "react-i18next";

const Precio = () => {

   /* I18N */
   const { t } = useTranslation(['precio']);


   return (
      <section className={css.container} id="precio-scroll-menu">
         <svg id="wave" style={{transform: "rotate(180deg)", transition: "0.3s"}} viewBox="0 0 1440 130" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stopColor="rgba(255, 255, 255, 1)" offset="0%"></stop><stop stopColor="rgba(255, 255, 255, 1)" offset="100%"></stop></linearGradient></defs><path style={{transform: "translate(0, 0px)", opacity: "1"}} fill="url(#sw-gradient-0)" d="M0,39L60,47.7C120,56,240,74,360,82.3C480,91,600,91,720,82.3C840,74,960,56,1080,56.3C1200,56,1320,74,1440,75.8C1560,78,1680,65,1800,62.8C1920,61,2040,69,2160,69.3C2280,69,2400,61,2520,58.5C2640,56,2760,61,2880,52C3000,43,3120,22,3240,13C3360,4,3480,9,3600,21.7C3720,35,3840,56,3960,73.7C4080,91,4200,104,4320,101.8C4440,100,4560,82,4680,75.8C4800,69,4920,74,5040,75.8C5160,78,5280,78,5400,73.7C5520,69,5640,61,5760,67.2C5880,74,6000,95,6120,104C6240,113,6360,108,6480,95.3C6600,82,6720,61,6840,60.7C6960,61,7080,82,7200,82.3C7320,82,7440,61,7560,56.3C7680,52,7800,65,7920,75.8C8040,87,8160,95,8280,97.5C8400,100,8520,95,8580,93.2L8640,91L8640,130L8580,130C8520,130,8400,130,8280,130C8160,130,8040,130,7920,130C7800,130,7680,130,7560,130C7440,130,7320,130,7200,130C7080,130,6960,130,6840,130C6720,130,6600,130,6480,130C6360,130,6240,130,6120,130C6000,130,5880,130,5760,130C5640,130,5520,130,5400,130C5280,130,5160,130,5040,130C4920,130,4800,130,4680,130C4560,130,4440,130,4320,130C4200,130,4080,130,3960,130C3840,130,3720,130,3600,130C3480,130,3360,130,3240,130C3120,130,3000,130,2880,130C2760,130,2640,130,2520,130C2400,130,2280,130,2160,130C2040,130,1920,130,1800,130C1680,130,1560,130,1440,130C1320,130,1200,130,1080,130C960,130,840,130,720,130C600,130,480,130,360,130C240,130,120,130,60,130L0,130Z"></path></svg>
         <div className={`${css['information-details']} max-width-global`}>
            <h2>{ t('title') }</h2>
            <div className={css['info-text-price-cont']}>
               <p>
                  { t('paragraph1') }
               </p>
               <div className={css['price-detail']}>
                  <div>
                     <p>{ t('since') }</p>
                     <h3>$ 1199 <span>COP</span></h3>
                     <h4>40&#162; USD</h4>
                     <p>{ t('perMinute') }</p>
                  </div>
               </div>
            </div>
         </div>
         <svg id="wave" style={{transform: "rotate(0deg)", transition: "0.3s"}} viewBox="0 0 1440 100" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stopColor="rgba(255, 255, 255, 1)" offset="0%"></stop><stop stopColor="rgba(255, 255, 255, 1)" offset="100%"></stop></linearGradient></defs><path style={{transform: "translate(0, 0px)", opacity: "1"}} fill="url(#sw-gradient-0)" d="M0,60L80,63.3C160,67,320,73,480,78.3C640,83,800,87,960,80C1120,73,1280,57,1440,46.7C1600,37,1760,33,1920,41.7C2080,50,2240,70,2400,65C2560,60,2720,30,2880,23.3C3040,17,3200,33,3360,46.7C3520,60,3680,70,3840,75C4000,80,4160,80,4320,75C4480,70,4640,60,4800,50C4960,40,5120,30,5280,23.3C5440,17,5600,13,5760,11.7C5920,10,6080,10,6240,23.3C6400,37,6560,63,6720,76.7C6880,90,7040,90,7200,90C7360,90,7520,90,7680,78.3C7840,67,8000,43,8160,31.7C8320,20,8480,20,8640,31.7C8800,43,8960,67,9120,76.7C9280,87,9440,83,9600,73.3C9760,63,9920,47,10080,33.3C10240,20,10400,10,10560,13.3C10720,17,10880,33,11040,40C11200,47,11360,43,11440,41.7L11520,40L11520,100L11440,100C11360,100,11200,100,11040,100C10880,100,10720,100,10560,100C10400,100,10240,100,10080,100C9920,100,9760,100,9600,100C9440,100,9280,100,9120,100C8960,100,8800,100,8640,100C8480,100,8320,100,8160,100C8000,100,7840,100,7680,100C7520,100,7360,100,7200,100C7040,100,6880,100,6720,100C6560,100,6400,100,6240,100C6080,100,5920,100,5760,100C5600,100,5440,100,5280,100C5120,100,4960,100,4800,100C4640,100,4480,100,4320,100C4160,100,4000,100,3840,100C3680,100,3520,100,3360,100C3200,100,3040,100,2880,100C2720,100,2560,100,2400,100C2240,100,2080,100,1920,100C1760,100,1600,100,1440,100C1280,100,1120,100,960,100C800,100,640,100,480,100C320,100,160,100,80,100L0,100Z"></path></svg>
      </section>
   );
};

export default Precio;
