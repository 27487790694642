import React, { useState, useRef, useEffect } from 'react';
import "./IndividualAudio.css";
import { pauseOtherAudios } from '../../../../utils/functions';
import { useTranslation } from "react-i18next";
import { audiosES, audiosEN } from "./utils";


const IndividualAudio = (props) => {

   /* DESTRUCTURING */
   const { setAudioSelected } = props;

   /* STATES */
   const [listAudios, setListAudios] = useState([]);
   const [arrayRefs, setArrayRefs] = useState([]);

   /* I18N */
   const { t, i18n } = useTranslation(['individual-audio']);

   /* HOOKS */
   const refAudio1 = useRef(null);
   const refAudio2 = useRef(null);
   const refAudio3 = useRef(null);
   const refAudio4 = useRef(null);
   const refAudio5 = useRef(null);
   const refAudio6 = useRef(null);
   const refAudio7 = useRef(null);

   /* USE EFFECT */
   useEffect(() => {
      setListAudios( i18n.language === 'es' ? audiosES : audiosEN );
   }, [i18n.language])

   useEffect(() => {
      /* Se cargan referencias de los audios para poder realizar el evento de que no suenen varios simultaneamente */
      setArrayRefs(["", refAudio1, refAudio2, refAudio3, refAudio4, refAudio5, refAudio6, refAudio7]);
   }, [])



   return (
      <section>
         <div className="container-player">
            <div className='audio-player'>
               <h3> { t('textAudio', { "num": 1 }) } </h3>
               <audio src={listAudios[1]} ref={refAudio1} onPlay={() => pauseOtherAudios(arrayRefs, 1)} controls>
                  Tu navegador no soporta el tag audio
               </audio>
            </div>

            <button
               type="button"
               className={`button-green-global`}
               onClick={() => setAudioSelected(1)}
            >
               { t('textButton') }
            </button>
         </div>

         <div className="container-player">
            <div className='audio-player'>
               <h3> { t('textAudio', { "num": 2 }) } </h3>
               <audio src={listAudios[2]} ref={refAudio2} onPlay={() => pauseOtherAudios(arrayRefs, 2)} controls>
                  Tu navegador no soporta el tag audio
               </audio>
            </div>
         

            <button
               type="button"
               className={`button-green-global`}
               onClick={() => setAudioSelected(2)}
            >
               { t('textButton') }
            </button>
         </div>

         <div className="container-player">
            <div className='audio-player'>
               <h3> { t('textAudio', { "num": 3 }) } </h3>
               <audio src={listAudios[3]} ref={refAudio3} onPlay={() => pauseOtherAudios(arrayRefs, 3)} controls>
                  Tu navegador no soporta el tag audio
               </audio>
            </div>
            

            <button
               type="button"
               className={`button-green-global`}
               onClick={() => setAudioSelected(3)}
            >
               { t('textButton') }
            </button>
         </div>

         <div className="container-player">
            <div className='audio-player'>
               <h3> { t('textAudio', { "num": 4 }) } </h3>
               <audio src={listAudios[4]} ref={refAudio4} onPlay={() => pauseOtherAudios(arrayRefs, 4)} controls>
                  Tu navegador no soporta el tag audio
               </audio>
            </div>
            
            <button
               type="button"
               className={`button-green-global`}
               onClick={() => setAudioSelected(4)}
            >
               { t('textButton') }
            </button>
         </div>

         <div className="container-player">
            <div className='audio-player'>
               <h3> { t('textAudio', { "num": 5 }) } </h3>
               <audio src={listAudios[5]} ref={refAudio5} onPlay={() => pauseOtherAudios(arrayRefs, 5)} controls>
                  Tu navegador no soporta el tag audio
               </audio>
            </div>

            <button
               type="button"
               className={`button-green-global`}
               onClick={() => setAudioSelected(5)}
            >
               { t('textButton') }
            </button>
         </div>

         <div className="container-player">
            <div className='audio-player'>
               <h3> { t('textAudio', { "num": 6 }) } </h3>
               <audio src={listAudios[6]} ref={refAudio6} onPlay={() => pauseOtherAudios(arrayRefs, 6)} controls>
                  Tu navegador no soporta el tag audio
               </audio>
            </div>

            <button
               type="button"
               className={`button-green-global`}
               onClick={() => setAudioSelected(6)}
            >
               { t('textButton') }
            </button>
         </div>

         <div className="container-player">
            <div className='audio-player'>
               <h3> { t('textAudio', { "num": 7 }) } </h3>
               <audio src={listAudios[7]} ref={refAudio7} onPlay={() => pauseOtherAudios(arrayRefs, 7)} controls>
                  Tu navegador no soporta el tag audio
               </audio>
            </div>

            <button
               type="button"
               className={`button-green-global`}
               onClick={() => setAudioSelected(7)}
            >
               { t('textButton') }
            </button>
         </div>
      </section>
   );
};

export default IndividualAudio;
