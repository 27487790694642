// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_loader-container__3A2KP {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;
    padding-top: 5%;
}

.Loading_custom-loader__ANDwg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background:
            radial-gradient(farthest-side,#3ACB89 94%,#0000) top/8px 8px no-repeat,
            conic-gradient(#0000 30%,#3ACB89);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:Loading_s3__UO-xU 1s infinite linear;
}

@keyframes Loading_s3__UO-xU{
    100%{transform: rotate(1turn)}
}

.Loading_loader-container__3A2KP p {
    font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB;;6CAEyC;IACzC,0EAA0E;IAC1E,8CAA+B;AACnC;;AAEA;IACI,KAAK,wBAAwB;AACjC;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".loader-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    row-gap: 30px;\n    padding-top: 5%;\n}\n\n.custom-loader {\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    background:\n            radial-gradient(farthest-side,#3ACB89 94%,#0000) top/8px 8px no-repeat,\n            conic-gradient(#0000 30%,#3ACB89);\n    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);\n    animation:s3 1s infinite linear;\n}\n\n@keyframes s3{\n    100%{transform: rotate(1turn)}\n}\n\n.loader-container p {\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-container": `Loading_loader-container__3A2KP`,
	"custom-loader": `Loading_custom-loader__ANDwg`,
	"s3": `Loading_s3__UO-xU`
};
export default ___CSS_LOADER_EXPORT___;
