import React from 'react';
import {Route, Routes} from "react-router-dom";
import BodyLanding from "../components/BodyLanding";
import ContactIntenalPage from "../components/ContactIntenalPage";


const Routing = () => {
   return (
      <Routes>
         <Route path="/" element={ <BodyLanding /> } />
         <Route path="/contact" element={ <ContactIntenalPage /> } />
      </Routes>
   );
};

export default Routing;
