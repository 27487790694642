import React, { Suspense } from "react";
import Header from "./components/Header";
import BodyLanding from "./components/BodyLanding";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import {BrowserRouter} from "react-router-dom";
import Routing from "./routes/Routing";


function App() {
  return (
    <Suspense fallback={<Loading />}>
       <BrowserRouter>
          <Header />

          <main>
             <Routing />
          </main>

          <Footer />
       </BrowserRouter>
    </Suspense>
  );
}

export default App;
