export const data_audios_ES = [
   {},
   {
      "nombre_archivo": "A-dónde-tan-yo.wav",
      "canales": 2,
      "duracion_seg": 53.08,
      "duracion_min": 0.88,
      "transcripcion": "¿Aló? ¿Con quién hablo? Hola, primita. ¿Y esa formalidad? Hola, Carlitos. Gracias por llamar. ¿Cómo te puedo ayudar? No, primita. Era para saber si podrías... Es para mí. ¿Permites un segundo en la línea, por favor? ¿Cómo? Bueno, sí, yo espero. Gracias por tu permanencia en la línea. ¿Me puedes recordar cuál era su solicitud? Ay, prima. Era para saber si podrías venir a comer por la noche. Ay, primito. Por el momento no puedo, pero cualquier cambio yo me comunico con usted en un momento oportuno. ¿No puedes hablar, prima? Ay, no. Sí, sí puedo hablar, primito. ¿Tiene alguna otra cosa en la que le puedo ayudar el día de hoy? No, prima. Ah, bueno, primito. Entonces, saludos y lo dejo con una breve encuesta para que le fique mi servicio. Prima, estás bien parpadeada. Sí estás secuestrada.",
      "saludo_presentacion": 1,
      "sentimiento": 1,
      "pqr": 1,
      "escucha_activa": 0,
      "comunicacion_efectiva": 0,
      "solucion_problema": 0,
      "cortesia_profesionalismo": 0,
      "cierre_llamada": 0,
      "groserias": 0,
      "modelo_texto": "gpt-4",
      "modelo_audio": "whisper-1"
   },
   {
      "nombre_archivo": "audioHE_1.wav",
      "canales": 2,
      "duracion_seg": 53.91,
      "duracion_min": 0.9,
      "transcripcion": "Me comunico con ustedes porque tengo un problema con sus servicios y estoy en la linea una maldita hora y los asesores ineptos son unos ineptos. Eh, oye bien amigo, entiendo tus preocupaciones pero ese no es el modo de hablar de alguien que trata de ayudarte a resolver tu problema. Dígame de manera calmada cual es su problema para ayudarlo a solucionar. Tampoco he de hablarme de esa manera, el problema que tengo es que mi computador no reacciona al teclado ni al ratón, por lo cual necesito ayuda de como resolver este problema, pero he estado en el teléfono por media hora y no me han dado solución. Ya entiendo su problema, no es la gran cosa y es bastante fácil de arreglar, por lo que veo es bastante inútil con las computadoras, para resolver ese problema tiene que presionar el botón de apagado y encendido durante unos segundos hasta que se apague su computador y después vuelve a encender. Con eso su teclado y ratón debe funcionar.",
      "saludo_presentacion": 0,
      "sentimiento": 2,
      "pqr": 1,
      "escucha_activa": 0,
      "comunicacion_efectiva": 0,
      "solucion_problema": 1,
      "cortesia_profesionalismo": 0,
      "cierre_llamada": 0,
      "groserias": 1,
      "modelo_texto": "gpt-4",
      "modelo_audio": "whisper-1"
   },
   {
      "nombre_archivo": "audioSE_1.wav",
      "canales": 2,
      "duracion_seg": 58.63,
      "duracion_min": 0.98,
      "transcripcion": "Estoy teniendo un problema con mi cuenta, no puedo acceder a mi información en línea. Entiendo, ¿podría proporcionarme su nombre completo y un número de cuenta para que pueda verificar su cuenta y ayudarlo con el problema? Sí, mi nombre es Juan Pérez y mi número de cuenta es 356-23798. Muy bien, déjeme revisar su cuenta. Parece que ha habido un problema con su cuenta y actualmente está desactivada. Me aseguraré de solucionar el problema y volver a activar su cuenta. ¿Podría proporcionarme su dirección de correo electrónico para que pueda enviarle actualizaciones sobre este proceso? Sí, mi dirección de correo electrónico es juanperez.com. Gracias, he registrado su dirección de correo electrónico. Le enviaré una actualización tan pronto como su cuenta esté activada nuevamente. ¿Hay algo más en lo que pueda ayudarlo hoy? No, eso es todo por ahora. Gracias por su ayuda. No hay problema. Si necesita algo más, no duden en ponerse en contacto con nosotros nuevamente.",
      "saludo_presentacion": 0,
      "sentimiento": 1,
      "pqr": 1,
      "escucha_activa": 1,
      "comunicacion_efectiva": 1,
      "solucion_problema": 1,
      "cortesia_profesionalismo": 1,
      "cierre_llamada": 1,
      "groserias": 0,
      "modelo_texto": "gpt-4",
      "modelo_audio": "whisper-1"
   },
   {
      "nombre_archivo": "Call-center-animado-__-llamada-real-de-venta.wav",
      "canales": 2,
      "duracion_seg": 43.19,
      "duracion_min": 0.72,
      "transcripcion": "Hola Santiago, ¿cómo estás? Te habla Andrés, asesor comercial y encargado de tu servicio telefónico. ¿Cómo estás? Bien, gracias. No, no estoy interesado, muchísimas gracias. Mira Santiago, el motivo de mi llamado es breve y es para darte un beneficio hasta del 7% en tu factura del móvil. Eh, ¿los qué? Solo te tomará 3 minutos, Santiago. Mira, el beneficio consiste en hacer una portabilidad o cambio de tu servicio en el cual debes estar pagando casi $70,000 pesos, ahora vas a pagar solamente $65,500 mensuales. ¿Qué te parece? Interesante, ¿verdad? Pues, es que yo ya estoy bien con mi... Sin embargo, podrías estar mejor, Santiago. Mira, el ahorro es casi de $5,000 pesos al mes.",
      "saludo_presentacion": 1,
      "sentimiento": 1,
      "pqr": 0,
      "escucha_activa": 0,
      "comunicacion_efectiva": 0,
      "solucion_problema": 0,
      "cortesia_profesionalismo": 1,
      "cierre_llamada": 0,
      "groserias": 0,
      "modelo_texto": "gpt-4",
      "modelo_audio": "whisper-1"
   },
   {
      "nombre_archivo": "Ejemplo-de-venta-con-persuasión.wav",
      "canales": 2,
      "duracion_seg": 55.68,
      "duracion_min": 0.93,
      "transcripcion": "Hace un mes le llamó un compañero mío y quería saber si le había gustado la información. ¿Qué información? Puede que la haya tirado sin darse cuenta, pero lo más importante es que nos comprometimos a llamarle. Escuche, voy a ofrecerle una oportunidad de inversión que creo que encontrará muy interesante. Ya, pero ahora no estoy interesado en comprar nada. Dígame Harry, ¿está casado o es feliz? Llevo casado 10 años. ¿Ah sí? Estupendo, estupendo. Vaya, yo llevo 6. Bien, oiga, ¿está invirtiendo en bolsa? Bueno, no exactamente, tengo pocas acciones. Fue el regalo de boda de unos amigos de Nueva York. Harry, escúcheme, tenemos una situación explosiva en este momento. Una empresa farmacéutica, Farrow Tech, ha elaborado el Paratin, un producto que está a punto de ser aprobado. ¿Para qué es? Muy buena pregunta, es muy interesante y la respuesta es aún mejor. Ayuda al desarrollo de los bebés prematuros. Parece una buena idea. Lo es, lo es. Dígame, ¿cuánto ha ganado con esas acciones que tiene?",
      "saludo_presentacion": 0,
      "sentimiento": 1,
      "pqr": 0,
      "escucha_activa": 1,
      "comunicacion_efectiva": 0,
      "solucion_problema": 0,
      "cortesia_profesionalismo": 1,
      "cierre_llamada": 0,
      "groserias": 0,
      "modelo_texto": "gpt-4",
      "modelo_audio": "whisper-1"
   },
   {
      "nombre_archivo": "movicelgo.wav",
      "canales": 2,
      "duracion_seg": 47.69,
      "duracion_min": 0.79,
      "transcripcion": "Eh, ¿aló? Buenos días, ¿usted es el titular de la línea? Eh, sí, sí, soy yo mismo. ¿Me puede decir su nombre, por favor? Eh, ¿Gabriel? Mire, señor Gabriel, le llamo de Moviselgo para ofrecerle una promoción. Consiste en la instalación de una línea adicional en su casa, donde usted tendrá derecho... Eh, perdón, señorita, pero es que, ¿exactamente quién es usted? Mi nombre es Marcela Restrepo de Moviselgo y estamos llamando... Sí, sí, eh, Marcela, discúlpeme, pero para nuestra seguridad, eh, me gustaría comprobar algunos datos antes de continuar con esta conversación. ¿Le importa? No, no tengo problema, señor. ¿Desde qué teléfono me llama? Es que en la pantallita aquí del mío sale número privado. El interno mío es 1004. Ya, ¿y para qué departamento de Moviselgo trabaja usted? Telemárquete Inactivo. Perdón, ¿me podría dar su identificación de trabajadora de Moviselgo? No...",
      "saludo_presentacion": 1,
      "sentimiento": 2,
      "pqr": 1,
      "escucha_activa": 1,
      "comunicacion_efectiva": 0,
      "solucion_problema": 0,
      "cortesia_profesionalismo": 0,
      "cierre_llamada": 0,
      "groserias": 0,
      "modelo_texto": "gpt-4",
      "modelo_audio": "whisper-1"
   },
   {
      "nombre_archivo": "pillofon.wav",
      "canales": 2,
      "duracion_seg": 57.63,
      "duracion_min": 0.96,
      "transcripcion": "Bueno, buenas tardes, ¿puedo hablar con Pillofon? Si, gracias por llamar a Pillofon, ¿en qué te puedo ayudar? Pues estoy hablando porque quiero contratar a través de la página, pero no sé cuáles son los requisitos Ah, está muy fácil, mira, te explico Por cierto, te saludo, yo soy Luisito, ahí vine al call center, estoy acá aprendiendo No es cierto, no es cierto, me están mintiendo No, es real, es completamente real ¿Es algo real? Es real ¿Es en serio? Voy a llamar mi llamada en este momento, nunca pensé que lo iba a contratar Luisito Si, si, no, es real, o sea, de hecho, sé... No, ya en este momento, no, no, no puedo creer, ahora con más curiosidad lo voy a contratar Pillofon Sé muy poco lo que estoy haciendo, María, entonces teme paciencia porque es mi primer día trabajando aquí Pero sí te puedo ayudar, estoy casi seguro Ok, muchas gracias Entonces mira, es muy fácil María, en la página le das click al plan que quieras",
      "saludo_presentacion": 1,
      "sentimiento": 1,
      "pqr": 1,
      "escucha_activa": 0,
      "comunicacion_efectiva": 0,
      "solucion_problema": 1,
      "cortesia_profesionalismo": 0,
      "cierre_llamada": 1,
      "groserias": 0,
      "modelo_texto": "gpt-4",
      "modelo_audio": "whisper-1"
   }
]

export const data_audios_EN = [
   {},
   {
      "nombre_archivo": "audioconversiaen_01_2023_08_23_18_40.wav",
      "canales": 1,
      "duracion_seg": 67.76,
      "duracion_min": 1.12,
      "transcripcion": "Hi, thank you for calling Questron Bank. This is Candace, how may I help you? Hello, can you hear me? Yes, I can hear you. How may I help you today? Oh thank god, listen, I need your help right now. My purse was stolen and my credit card was in it. I'm in so much panic right now. I called your automated system and found that there's a lot of transactions that I did not make I lost access to the app. I tried logging into it, but it wouldn't let me Somebody's draining my account as we speak and I need you to stop it right now I'm sorry this happened to you, but don't worry. You're calling the right person I can definitely help you with this now in this situation immediately freezing your account is the first thing we need to do to block further transactions how many lost cards are you calling about just one thank God I'm separate persons for different cards but there's a huge problem My phone was in there too and that phone is tied to that card. So I need you to help me right now Okay, I will do it quickly We definitely need to block your card right now for me to do this I will now be verifying your identity and by asking you a series of security questions, and then I can take action from there. Okay?",
      "saludo_presentacion": 1,
      "sentimiento": 2,
      "pqr": 1,
      "escucha_activa": 1,
      "comunicacion_efectiva": 1,
      "solucion_problema": 1,
      "cortesia_profesionalismo": 1,
      "cierre_llamada": 1,
      "groserias": 0,
      "modelo_texto": "gpt-4",
      "modelo_audio": "Deepgram-whisper"
   },
   {
      "nombre_archivo": "audioconversiaen_02_2023_08_23_21_20.wav",
      "canales": 1,
      "duracion_seg": 88.07,
      "duracion_min": 1.46,
      "transcripcion": "Reason to limit him this is Gina may help you. Yeah hi I'm waiting for my limo to show up it's about 30 minutes late already. Oh I'm so sorry about that if you could give me the pickup time and location we can get this settled for you. My daughter's going to her senior prom They're already a half an hour late. I need to speak to someone. I totally understand and we're truly sorry. I'll have someone there as soon as possible. What time was the pick up? Six o'clock. Okay, and the location? 800 North Henderson Road. 800 North Henderson Road? Yes. And the town and zip code, please? King of Prussia, 19406. That's King of Prussia, 19406? Yes. Okay, and Am I speaking with Ron? Yes. Okay, if you don't mind staying on the line for just a moment, Drake is scheduled as your driver. I'm going to give him the call and I'll find out his whereabouts. Okay, this better not take long. Just a minute, please. Hello, Mr. Mendez? Yes. I spoke with Drake, he will be there in five minutes. He's supposed to be here half an hour ago, if he doesn't show up in five minutes. He was stuck in traffic, but He is now on First Avenue in King of Prussia and will be there momentarily. Alright. Oh, and I'm so sorry about the delay. Thanks for your help. You're welcome. Goodbye. Bye-bye.",
      "saludo_presentacion": 1,
      "sentimiento": 2,
      "pqr": 0,
      "escucha_activa": 1,
      "comunicacion_efectiva": 1,
      "solucion_problema": 1,
      "cortesia_profesionalismo": 1,
      "cierre_llamada": 1,
      "groserias": 0,
      "modelo_texto": "gpt-4",
      "modelo_audio": "Deepgram-whisper"
   },
   {
      "nombre_archivo": "audioconversiaen_03_2023_08_23_21_25.wav",
      "canales": 1,
      "duracion_seg": 88.85,
      "duracion_min": 1.48,
      "transcripcion": "Good evening Kingswood Apartments, this is Alex, how may I help you? Hey yeah, I'm in apartment 104 on the first floor, I'm calling to complain about my neighbors. Okay, what seems to be the problem, sir? It's very late, I just got my newborn baby to sleep and they're being loud again. I brought this to their attention several times, but they never, you know, never stop. Okay, I'm very sorry about that. Just let me take down your contact information and I'll contact the landlord right away to get this all straightened out. Okay, my name is Jeff Matthews. Okay, Mr. Matthews, can you spell that for me? First name is Jeff, J-E-F-F. Last name is Matthews, N-A-T-T-H-E-W-S. Okay, Mr. Matthews, can I have the best number you can be reached at and also your apartment number again? Sure. It's 610-265-1714 And I'm in apartment 104 on the first floor. Okay, I have 610-265-1714 and apartment 104. Yes. Alright, Mr. Matthews, I'm going to pass this information on to the landlord right away, and he'll be contacting you shortly. Is there anything else I can help you with today? No, that's all. Thank you. Thank you very much. Have a great night. You too.",
      "saludo_presentacion": 1,
      "sentimiento": 1,
      "pqr": 1,
      "escucha_activa": 1,
      "comunicacion_efectiva": 1,
      "solucion_problema": 1,
      "cortesia_profesionalismo": 1,
      "cierre_llamada": 1,
      "groserias": 0,
      "modelo_texto": "gpt-4",
      "modelo_audio": "Deepgram-whisper"
   },
   {
      "nombre_archivo": "audioconversiaen_04_2023_08_23_21_30.wav",
      "canales": 1,
      "duracion_seg": 72.52,
      "duracion_min": 1.20,
      "transcripcion": "Hi, thank you for calling Quistron Medical Supplies. This is Candice, how can I help you today? Hi Candice, this is Susan and I'm calling to order my monthly feeding supplies, my milk and my syringes. Can you help me with that please? Sure I'll be happy to assist you with that. May I have your full name and date of birth? Sure my name is Susan Smith. Date of birth is August 9th 1979. Thank you. Let me pull up your account. Give me one moment. Okay. And how's your day going? Oh, my day's really great. It's the middle of the summer, the weather's really nice. I couldn't ask for more. And yours? It's great. Although, I really don't care much for summer. I prefer winter and autumn. Really? Too hot? Too hot and too humid. Especially down here in Florida. Oh, well, I can only imagine it in Florida. But I like autumn too. It's good for photography. Really? Yes, absolutely.",
      "saludo_presentacion": 1,
      "sentimiento": 0,
      "pqr": 0,
      "escucha_activa": 1,
      "comunicacion_efectiva": 1,
      "solucion_problema": 0,
      "cortesia_profesionalismo": 1,
      "cierre_llamada": 0,
      "groserias": 0,
      "modelo_texto": "gpt-4",
      "modelo_audio": "Deepgram-whisper"
   },
   {
      "nombre_archivo": "audioconversiaen_05_2023_08_23_21_40.wav",
      "canales": 1,
      "duracion_seg": 86.71,
      "duracion_min": 1.44,
      "transcripcion": "Hi, thank you for calling Questron. This is Candace, how may I help you? Candace, what the actual f*** is going on with your company? Are you guys going bankrupt or something? As far as I know, the company is stable and doing great. May I know your concern? Oh let me see. First of all, you gave me a used voucher. Like, how could that even f***ing happen? Second, nobody's answering my email. Someone from your company promised to send me a voucher within 24 hours. It's been four f***ing days. The sale has ended. And I still have no f***ing voucher. Why? I seriously want to know why because it is such a f***ing mystery to me as to how a company could be this messed up. What in the actual f*** is going on? I need to know that somebody's gonna get fired for this or I'm out. I am done doing business with your company. I'm so sorry for any inconvenience we've cost you. Normally anyone from our company should be able to replace vouchers within 24 hours. Why do I have to beg for it? Why do I have to email you multiple times for a measly $10 discount? It's f***ing humiliating. I understand that. Do you? Really? You keep on saying you understand, but for all I know, you're just reading off a script.",
      "saludo_presentacion": 1,
      "sentimiento": 2,
      "pqr": 1,
      "escucha_activa": 1,
      "comunicacion_efectiva": 0,
      "solucion_problema": 0,
      "cortesia_profesionalismo": 0,
      "cierre_llamada": 0,
      "groserias": 1,
      "modelo_texto": "gpt-4",
      "modelo_audio": "Deepgram-whisper"
   },
   {
      "nombre_archivo": "audioconversiaen_06_2023_08_23_21_43.wav",
      "canales": 1,
      "duracion_seg": 84.13,
      "duracion_min": 1.40,
      "transcripcion": "Thank you for calling Martha's Flowers, town assist. Hello, I'd like to order flowers and I think you have what I'm looking for. I'd be happy to take care of your order, may I have your name please? Randall Thomas. Randall Thomas, can you spell that for me? Randall, R-A-N-D-A-L-L, Thomas, D-H-O-M-A-N. Thank you for that information, Randall. May I have your home or office number area code first. Area code 409 is in 5-8-6-6-5-0-8-8. That's 4-0-9-8-6-6-5-0-8-8. Do you have a fax number or email address? My email is randall.thomas at gmail.com randall.thomas at gmail.com may have your shipping address 6800 OK Gladys Avenue, Beaumont, Texas zip code is 77706 Gladys Avenue, Beaumont, Texas zip code 77706 Thank you for the information. What products were you interested in purchasing? Red roses, probably a dozen. One dozen of red roses? Do you want long stems? Sure. Alright, Randall, let me process your order. One moment please. Okay.",
      "saludo_presentacion": 1,
      "sentimiento": 0,
      "pqr": 0,
      "escucha_activa": 1,
      "comunicacion_efectiva": 1,
      "solucion_problema": 1,
      "cortesia_profesionalismo": 1,
      "cierre_llamada": 1,
      "groserias": 0,
      "modelo_texto": "gpt-4",
      "modelo_audio": "Deepgram-whisper"
   },
   {
      "nombre_archivo": "audioconversiaen_07_2023_08_23_21_47.wav",
      "canales": 1,
      "duracion_seg": 72.07,
      "duracion_min": 1.20,
      "transcripcion": "Hi, thank you for calling Questron Travel and Tours. This is Candace. How may I assist you today? Yes, Candace. Listen, I'm a little tense here. So the airline all of a sudden rescheduled my flight to tomorrow. It's supposed to be today at 9 a.m. And I sure booked that flight for a reason. I have an important dinner meeting this evening. So any flight after 3 p.m. Is useless to me. I need the soonest available flight today. Today, not tomorrow. And that's not all. I have a non-refundable hotel reservation. So you tell me what do I do now because I'm a tight spot here I'm so sorry for the experience. I will do my best to help you with this as soon as possible Why don't we start with your flight booking and then we'll go over your hotel reservation afterward. Okay, please. It's an important dinner meeting for work. I cannot miss it. I booked it so early and now this. I understand the urgency of your concern. My top priority right now is to get you the earliest flight possible before 3 p.m. As you said I'll check the options we have here for you may have your name and flight booking",
      "saludo_presentacion": 1,
      "sentimiento": 2,
      "pqr": 1,
      "escucha_activa": 1,
      "comunicacion_efectiva": 1,
      "solucion_problema": 1,
      "cortesia_profesionalismo": 1,
      "cierre_llamada": 1,
      "groserias": 0,
      "modelo_texto": "gpt-4",
      "modelo_audio": "Deepgram-whisper"
   }
]

export const setFalseOrTrueStr = (value, i18n) => {
   if(i18n.language === 'es'){
      return value === 0 ? "Falso" : "Verdadero";
   }

   if(i18n.language === 'en'){
      return value === 0 ? "False" : "True";
   }
}

export const getValueSentiment = (value, i18n) => {
   if(i18n.language === 'es'){
      return value === 0 ? "Neutro" : value === 1 ? "Positivo" : "Negativo";
   }

   if(i18n.language === 'en'){
      return value === 0 ? "Neutral" : value === 1 ? "Positive" : "Negative";
   }
}