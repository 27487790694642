/* Spanish audios */
import AudioES_1 from "../../../../assets/mp3/audio_es_01.wav";
import AudioES_2 from "../../../../assets/mp3/audio_es_02.wav";
import AudioES_3 from "../../../../assets/mp3/audio_es_03.wav";
import AudioES_4 from "../../../../assets/mp3/audio_es_04.wav";
import AudioES_5 from "../../../../assets/mp3/audio_es_05.wav";
import AudioES_6 from "../../../../assets/mp3/audio_es_06.wav";
import AudioES_7 from "../../../../assets/mp3/audio_es_07.wav";

/* English audios */
import AudioEN_1 from "../../../../assets/mp3/audio_en_01.wav";
import AudioEN_2 from "../../../../assets/mp3/audio_en_02.wav";
import AudioEN_3 from "../../../../assets/mp3/audio_en_03.wav";
import AudioEN_4 from "../../../../assets/mp3/audio_en_04.wav";
import AudioEN_5 from "../../../../assets/mp3/audio_en_05.wav";
import AudioEN_6 from "../../../../assets/mp3/audio_en_06.wav";
import AudioEN_7 from "../../../../assets/mp3/audio_en_07.wav";


export const audiosES = [
   {}, AudioES_1, AudioES_2, AudioES_3, AudioES_4, AudioES_5, AudioES_6, AudioES_7
]

export const audiosEN = [
   {}, AudioEN_1, AudioEN_2, AudioEN_3, AudioEN_4, AudioEN_5, AudioEN_6, AudioEN_7
]