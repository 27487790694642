import React, { useState, useEffect, useRef } from 'react';
import css from "./DemoPlayer.module.css";
import IndividualAudio from "./IndividualAudio";
import { data_audios_ES, data_audios_EN, setFalseOrTrueStr, getValueSentiment } from "../../../utils/data_audios";
import {Link, scroller} from "react-scroll";
import useWindowSize from "../../../hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import {audiosEN, audiosES} from "./IndividualAudio/utils";


const DemoPlayer = () => {

   /* STATES */
   const [audioSelected, setAudioSelected] = useState(null);
   const [dataAudio, setDataAudio] = useState(null);
   const [listDataAudios, setListDataAudios] = useState([]);

   /* DESTRUCTURING */
   const { width } = useWindowSize();

   /* HOOKS */
   const transRef = useRef(null);

   /* I18N */
   const { t, i18n } = useTranslation(['demo-player']);

   /* USE EFFECT */
   useEffect(() => {
      setListDataAudios( i18n.language === 'es' ? data_audios_ES : data_audios_EN );
      setDataAudio(null);
      setAudioSelected(null);
   }, [i18n.language])

   useEffect(() => {
      (() => {
         if(!audioSelected) {
            transRef.current.innerHTML = `${t('textInitTrans')}`;
            transRef.current.style.textAlign = "center";
         } else {
            setDataAudio(null);
            transRef.current.innerHTML = `${t('processingAudio')} &#x231B;`
            transRef.current.style.textAlign = "left";

            /* Si es tablet hacía abajo, se hace un scroll */
            if (width <= 768) {
               scroller.scrollTo("audio-transcription", {
                  spy: true,
                  smooth: true,
                  offset: -110
               });
            }
         }
      })();
   }, [audioSelected, i18n.language]);

   useEffect(() => {
      if(audioSelected) {
         setTimeout(() => {
            const data = listDataAudios[audioSelected];
            setDataAudio(data);
            transRef.current.innerHTML = data?.transcripcion;
         }, 3000)
      }
   }, [audioSelected]);




   return (
      <section className={`${css.container} max-width-global`}>
         <h2> { t('title') } </h2>

         <p>
            { t('description') } &#128071;&#128526;
         </p>

         <div className={css['player-text-cont']}>
            <IndividualAudio
               setAudioSelected={setAudioSelected}
            />

            <div className={css['container-audio-data']}>
               <div id="audio-transcription" className={css['audio-transcription']} ref={transRef} />

               {
                  dataAudio ? (
                     <div className={css['audio-data-complement']}>
                        <p>1. {t('insights.durationSeconds')} <span>{dataAudio.duracion_seg} s</span></p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;{t('insights.durationMinutes')} <span>{dataAudio.duracion_min} min</span></p>
                        <p>2. {t('insights.greeting')} <span>{setFalseOrTrueStr(dataAudio.saludo_presentacion, i18n)}</span></p>
                        <p>3. {t('insights.sentiment')} <span>{getValueSentiment(dataAudio.sentimiento, i18n)}</span></p>
                        <p>4. {t('insights.pqr')} <span>{setFalseOrTrueStr(dataAudio.pqr, i18n)}</span></p>
                        <p>5. {t('insights.listening')} <span>{setFalseOrTrueStr(dataAudio.escucha_activa, i18n)}</span></p>
                        <p>6. {t('insights.communication')} <span>{setFalseOrTrueStr(dataAudio.comunicacion_efectiva, i18n)}</span></p>
                        <p>7. {t('insights.solution')} <span>{setFalseOrTrueStr(dataAudio.solucion_problema, i18n)}</span></p>
                        <p>8. {t('insights.courtesy')} <span>{setFalseOrTrueStr(dataAudio.cortesia_profesionalismo, i18n)}</span></p>
                        <p>9. {t('insights.closeCall')} <span>{setFalseOrTrueStr(dataAudio.cierre_llamada, i18n)}</span></p>
                        <p>10. {t('insights.rudeness')} <span>{setFalseOrTrueStr(dataAudio.groserias, i18n)}</span></p>

                        <p className={css['msg-demo']}>
                           {t('insights.message')} <br/>
                           <Link to="contacto-scroll-menu" spy={true} smooth={true} offset={-100}>
                              {t('insights.linkDemo')}
                           </Link>
                        </p>
                     </div>
                  ) : null
               }
            </div>
         </div>

      </section>
   );
};

export default DemoPlayer;

