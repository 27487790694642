import React from 'react';
import css from "./Seccion1.module.css";
import {Link, scroller} from "react-scroll";
import { useTranslation } from "react-i18next";

import IconSquare1 from "../../../assets/icons/icon-people-win.png";
import IconSquare2 from "../../../assets/icons/icon-speak.png";
import IconSquare3 from "../../../assets/icons/icon-program.png";
import IconSquare4 from "../../../assets/icons/icon-people-think.png";
import IconSquare5 from "../../../assets/icons/icon-like.png";

import ImgReport from "../../../assets/img/reporte.webp";

const Seccion1 = () => {

   /* I18N */
   const { t } = useTranslation(['seccion1']);

   return (
      <section className={css.container} id="herramienta-scroll-menu">
         <div className={`${css['first-info-image']} max-width-global`}>
            <div className={css['info-text']}>
               <p dangerouslySetInnerHTML={{ __html: t('paragraph') }} />

               <button
                  type="button"
                  className="button-blue-global"
                  onClick={() => {
                     scroller.scrollTo("contacto-scroll-menu", {
                        spy: true,
                        smooth: true,
                        offset: -100
                     });
                  }}
               >
                  { t('textButton') }
               </button>
            </div>
            <div className={css['info-image']}>
               <div className={css['image-background']} />
            </div>
         </div>

         <div className={`${css['report-container']} max-width-global`}>
            <div className={css['report-container-wrapper']}>
               <div className={css['report-image-items']}>
                  <div className={css['report-image']}>
                     <img src={ImgReport} alt="Imagen reporte ConversIA Looker Studio" width={1009} height={498}/>
                     <a
                        href="https://lookerstudio.google.com/embed/reporting/17957c4c-b95f-4b1e-8549-67aade56c41d/page/p_bgw3thw26c"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="button-green-global"
                     >
                        { t('report.textButton') }
                     </a>
                  </div>
                  <div className={css['report-items']}>
                     <ul>
                        <li>{ t('report.item1') }</li>
                        <li>{ t('report.item2') }</li>
                        <li>{ t('report.item3') }</li>
                        <li>{ t('report.item4') }</li>
                     </ul>
                  </div>
               </div>
               <p className={css['report-description']} dangerouslySetInnerHTML={{ __html: t('report.description') }} />
               <span className={css['report-subdescription']}>
                  {t('report.subdescription')}&nbsp;
                  <Link to="contacto-scroll-menu" spy={true} smooth={true} offset={-100}>
                     { t('report.contactUs') }
                  </Link>
                  .
                  </span>
            </div>
         </div>

         <div className={css['section-items-container']}>
            <div className={`${css['section-items']} max-width-global`}>
               <h2> { t('subsection.title') } </h2>

               <p> { t('subsection.description') } </p>

               <div className={css['items-list']}>
                  <div className={css['item-detail']}>
                     <div className={css['item-image']}>
                        <img src={IconSquare1} alt="Identificar áreas de mejora" width={501} height={501}/>
                     </div>
                     <p> { t('subsection.detail1') } </p>
                  </div>
                  <div className={css['item-detail']}>
                     <div className={css['item-image']}>
                        <img src={IconSquare2} alt="Comprender las necesidades" width={501} height={501}/>
                     </div>
                     <p> { t('subsection.detail2') } </p>
                  </div>
                  <div className={css['item-detail']}>
                     <div className={css['item-image']}>
                        <img src={IconSquare3} alt="Analizar el desempeño de atención al cliente" width={501} height={501}/>
                     </div>
                     <p> { t('subsection.detail3') } </p>
                  </div>
                  <div className={css['item-detail']}>
                     <div className={css['item-image']}>
                        <img src={IconSquare4} alt="Mejorar la formación del personal" width={501} height={501}/>
                     </div>
                     <p> { t('subsection.detail4') } </p>
                  </div>
                  <div className={css['item-detail']}>
                     <div className={css['item-image']}>
                        <img src={IconSquare5} alt="Cumplimiento de auditorías" width={501} height={501}/>
                     </div>
                     <p> { t('subsection.detail5') } </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Seccion1;
