import React from 'react';
import css from "./TecnologiasPartners.module.css";
import { useTranslation } from "react-i18next";

import LogoDeepgram from "../../../assets/img/deepgram.png";
import LogoMicStartups from "../../../assets/img/microsoft-for-startups.png";
import LogoAzure from "../../../assets/img/azure.png";
import LogoOpenAI from "../../../assets/img/openAI.png";

const TecnologiasPartners = () => {

   /* I18N */
   const { t } = useTranslation(['tecnologias-partners']);


   return (
      <section className={`${css.container} max-width-global`}>
         <h2 className={css['h2-title']}> { t('title') } </h2>
         <p className={css['p-paragraph']}>
            { t('paragraph') }
         </p>
         <div className={css['tech-logos']}>
            <img src={LogoDeepgram} alt="Logo Deepgram" width={298} height={60}/>
            <img src={LogoMicStartups} alt="Logo Microsoft for Startups" width={143} height={60}/>
            <img src={LogoAzure} alt="Logo Azure" width={208} height={60}/>
            <img src={LogoOpenAI} alt="Logo Open AI" width={221} height={60}/>
         </div>
      </section>
   );
};

export default TecnologiasPartners;
