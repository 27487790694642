import React from 'react';
import css from "./Contacto.module.css";
import { useTranslation } from "react-i18next";

import MauricioPhoto from "../../../assets/img/mauricio-parrado.webp";

const Contacto = () => {

   /* I18N */
   const { t } = useTranslation(['contacto']);


   return (
      <section className={`${css.container} max-width-global`} id="contacto-scroll-menu">
         <h2 className={css.title}> { t('title') } </h2>

         <div className={css['photo-info-cont']}>
            <div className={css['photo']}>
               <img src={MauricioPhoto} alt="Foto Mauricio Parrado" width={522} height={522}/>
            </div>
            <div className={css['info-details']}>
               <p>{t('paragraph')} ✌️</p>

               <a
                  href="/contact"
                  className="button-blue-global"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  Agendar una reunión
               </a>
            </div>
         </div>
      </section>
   );
};

export default Contacto;
