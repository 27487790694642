import React, { useEffect } from 'react';
import css from "./ContactIntenalPage.module.css";
import { useTranslation } from "react-i18next";

const ContactIntenalPage = () => {

   /* I18N */
   const { t } = useTranslation(['contacto']);

   /* USE EFFECT */
   useEffect(() => {
      const script = document.createElement("script");
      script.src =
         "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
      script.onload = function (ev) {
         console.log("script loaded!!");
      };
      script.onerror = function (ev) {
         console.log("script failed to load." + ev);
      };
      document.body.appendChild(script);
   }, []);


   return (
      <section className={`${css.container} max-width-global`}>
         <h1>{ t('title') }</h1>

         <div className="meetings-iframe-container"
              data-src="https://meet.trascenderglobal.com/meetings/mauricio-parrado/mauricio-parrado?embed=true">
         </div>
      </section>
   );
};

export default ContactIntenalPage;
